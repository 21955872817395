import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { gsap, Power1 } from "gsap";

import Layout from "../components/layout/layout";
import Leistung from "../components/leistung";

//Data
import Heizung from "../images/Icon Heizungsbau.png";
import Sanitaer from "../images/Icon Sanitär.png";
import Energien from "../images/Icon Erneuerbare Energien.png";
import Planung from "../images/Icon Badkonzepte.png";
import leistung from "../data/leistungen.json";

const TextContainer = styled.div`
  padding: 0px 30px 50px 30px;
  text-align: center;
  h2 {
    margin-top: 60px;
  }
  @media (max-width: 768px) {
    padding: 0px 30px 10px 30px;
  }
`;

const LeistungContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;

`;

const TimelineContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;
  @media (max-width: 767px) {
    width: 95vw;
    padding: 0px;
    ul {
      padding: 0px;
    }
  }
`;

class Unternehmen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 })
      .to(container, {
        duration: 0.8,
        opacity: 1,
        y: 0,        
        ease: Power1.easeOut,
      })
  }
  render() {
    return (
      <Layout pageTitle="Ein Unternehmen - Eine Familie" description="100 Jahre Qualität seit 4 Generationen.      ">
        <div>
          <div className="unternehmen--background" alt="Hengel GmbH"></div>

          <TextContainer>
            <h2>100 Jahre Qualität seit 4 Generationen.</h2>
          </TextContainer>
          <TimelineContainer>
            <Timeline align="alternate">
              <TimelineItem>
                <TimelineOppositeContent>
                  <p className="font__blue">Firmengründung 1921</p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className="hyphens">
                  <p>
                    Aus der Notwendigkeit wirtschaftlich schwieriger Zeiten und
                    mit dem nötigen Selbstvertrauen gründete Hermann Hengel sen.
                    1921 seinen eigenen Handwerksbetrieb rund um Flaschnerei und
                    Sanitär&shy;installationen in Markgröningen. Mit Beharrlichkeit
                    und Tatkraft steuerte er seinen Betrieb durch den 2.
                    Weltkrieg und machte sich einen Namen bei Um- und Neubauten
                    aller Art.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <p className="font__blue">
                    2. Generation: Werner Hengel & Hermann Hengel
                  </p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className="hyphens">
                  <p>
                    Neugierde und Visionen in aufstrebenden Zeiten öffneten dem
                    Unternehmen neue Türen. Schon bald wurden neue
                    Kunden&shy;bedürfnisse geweckt und daraus resultierenden
                    Geschäftsfelder in der Heizung und Warmwasser&shy;versorgung. Auf
                    Holz und Kohle folgten Öl und Gas. Heute ist Hengel zudem
                    der Fachmann für erneuerbare Energien wie Solar- oder
                    Pelletsheizung.
                  </p>
                  <p>
                    Möglich wurde diese Erfolgs&shy;geschichte durch feste
                    Familienbande. Hermann Hengel Junior übernahm den Betrieb
                    mit seinem Bruder Werner von Hermann Hengel Senior.{" "}
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <p className="font__blue">3. Generation; Kurt Hengel </p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className="hyphens">
                  <p>
                    Auf ihn folgte Kurt Hengel mit seinem Bruder Helmut Hengel –
                    bereits in der dritten Generation. Vor allem mit der »guten
                    Seele« des Unternehmens, Marta Hengel, die als starke Frau
                    im Hintergrund die Fäden fest in der Hand hielt, konnten
                    Kundenwünsche klar erfüllt werden.
                  </p>
                  <p>
                    Kurt Hengel baute das Unternehmen mit seinem großen
                    technischen Wissen zu dem nachhaltig und innovativen
                    Traditionsbetrieb aus, der seinen Kunden in und rund um
                    Markgröningen heute kompetent zur Seite steht.
                  </p>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineOppositeContent>
                  <p className="font__blue">4. Generation: Simon Schädlich</p>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className="hyphens">
                  <p>
                  Mit der Gründung der Hengel GmbH 2025 wurde der nächste Schritt eingeleitet. Mit der Begeisterung und der Verpflichtung, als Urenkel des Firmengründers, Hengel in die Zukunft  zu führen ist Simon Schädlich mit frischem Wind am Start.
                  </p>
                  <p>
                  Ob Industrie, Gewerbe, die öffentliche Hand oder vor allem im privaten Wohnungsbereich – das Familienunternehmen Hengel beweist bis heute Tag für Tag sein Können in Sachen Handwerksqualität.

                  </p>
                  <p>
                  Individuell, zuverlässig und auf hohem Niveau – überzeugen Sie sich selbst

                  </p>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </TimelineContainer>
          <hr />
          <br />
          <TextContainer>
            <h4>Unser Service Angebot</h4>
          </TextContainer>
          <LeistungContainer>
            <Grid container spacing={8}>
              {leistung.Leistungen.map((item) => (
                <Grid item md={3}>
                  <Leistung
                    icon={
                      item.heading === "Heizungsbau"
                        ? Heizung
                        : item.heading === "Sanitär"
                        ? Sanitaer
                        : item.heading === "Erneuerbare Energien"
                        ? Energien
                        : item.heading === "Badkonzepte" &&
                          Planung
                    }
                    header={item.heading}
                    text={item.text}
                    slug={item.slug}
                  />
                </Grid>
              ))}
            </Grid>
          </LeistungContainer>

        </div>
      </Layout>
    );
  }
}

export default Unternehmen;
