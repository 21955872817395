import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { gsap, Power1 } from "gsap";

import Layout from "../components/layout/layout";
import Leistung from "../components/leistung";
import TextZwei from "../components/textZwei";
import InfoText from "../components/infoText";
import LeistungHero from "../components/leistungHero";
import Testimonial from "../components/testimonial";
import TestimonialZwei from "../components/testimonialZwei";

//Data
import Heizung from "../images/Icon Heizungsbau.png";
import Energien from "../images/Icon Erneuerbare Energien.png";
import Planung from "../images/Icon Badkonzepte.png";
import Background from "../images/Sanitär.jpg";

import leistung from "../data/leistungen.json";
import data from "../data/sanitaer.json";

const TextContainer = styled.div`
  padding: 100px 0px 20px 0px;
  text-align: center;
`;

const LeistungContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;
`;
const ImageContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  @media only screen and (max-width: 767px){
    display: block;
  }

`;

class SanitaerLeistung extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 }).to(container, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    });
  }
  render() {
    return (
      <Layout
        pageTitle="Sanitär"
        description="Wir sind ihr Partner bei Neuinstallation und Trinkwasserhygiene mit Legionellenschutz.      "
      >
        <LeistungHero
          heading={data.heading}
          text={data.intro}
          imageUrl={`url("${Background}")`}
        />
        <InfoText
          heading="Vertrauen Sie dem Experten für Sanitär"
          list={data.details.list} 
        />
        <TextContainer>
          {" "}
          <h2>Besuchen Sie die Badausstellung unserer Partner</h2>
          <p>Sprechen Sie uns an! Gerne planen wir einen Besuchtermin bei unseren Partnern für Sie.</p>
        </TextContainer>
        <ImageContainer>
          <a href="https://www.elements-show.de/kornwestheim" target="_blank" rel="noopener noreferrer">
            {" "}
            <div item md={6} className="background__elements">
              <h4><b>Badausstellung Elements in Kornwestheim</b></h4>
            </div>
          </a>
          <a
            href="https://www.lotter.de/privatkunden/sanitaer/baedergalerie/"
            target="_blank" rel="noopener noreferrer"
          >
            {" "} 
            <div item md={6} className="background__lotter" >
              <h4><b>Bädergalerie Lotter in Ludwigsburg & Backnang</b></h4>
            </div>
          </a>
        </ImageContainer>
        <TextZwei heading={data.info.heading} text={data.info.text} />
        <Testimonial
          heading={data.kundenstimme.heading}
          text={data.kundenstimme.text} />
          <TestimonialZwei
          heading={data.kundenstimmeZwei.heading}
          text={data.kundenstimmeZwei.text} />
        
        
        <hr />
        <TextContainer>
          <h4>Wir bieten auch</h4>
        </TextContainer>
        <LeistungContainer>
          <Grid container spacing={8}>
            {leistung.Leistungen.filter(function (item) {
              if (item.heading === "Sanitär") {
                return false;
              }
              return true;
            }).map((item) => (
              <Grid item md={4}>
                <Leistung
                  icon={
                    item.heading === "Heizungsbau"
                      ? Heizung
                      : item.heading === "Erneuerbare Energien"
                      ? Energien
                      : item.heading === "Badkonzepte" &&
                        Planung
                  }
                  header={item.heading}
                  slug={item.slug}
                  text={item.text}
                />
              </Grid>
            ))}
          </Grid>
        </LeistungContainer>
      </Layout>
    );
  }
}

export default SanitaerLeistung;
