import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
    wrapper: {
      margin: "0px auto 50px auto",
      textAlign: "center",

    },
    item: {
      maxWidth: "800px",
      margin: "0px auto",
      padding: "0px 80px 80px",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "30px",
      },
    },

}));

export default function TestimonialZwei(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12}  className={classes.item}  >
          <p><i>{props.text}</i></p>
          <h4>{props.heading}</h4>

        </Grid>
      </Grid>
    </>
  );
}
