import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import Logo from "../../images/Logo Hengel.png";
import Phone from "../../images/phone_small.png";

// Data
import menudata from "../../data/menu.json";

const useStyles = makeStyles((theme) => ({
  barWrapper: {
    width: "100vw",
    height: "40px",
    backgroundColor: "#666",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  barItem: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  barIcon: {
    height: "16px",
    padding: "3px 5px 0px 0px",
  },
  navWrapper: {
    height: "100px",
    padding: "0px 30px",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logoWrapper: {
    top: "20px",
    left: "30px",
    "& img": {
      [theme.breakpoints.up("md")]: {
        width: "240px",
      },
      [theme.breakpoints.down("md")]: {
        width: "140px",
      },
    },
  },
  menuWrapper: {
    "& button": {
      padding: "0px 25px",
      "& p": {
        letterSpacing: "2px",
      },
      "& a": {
        letterSpacing: "2px",
      },
    },
  },
}));
export default function Navbar(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <>
      <Grid container className={classes.barWrapper} >
        <Grid item className={classes.barItem}>
          <p className="font__white margin__none">
            Familienunternehmen mit Tradition
          </p>
          <p className="font__white margin__none">
            Bahnhofstraße 14, 71706 Markgröningen
          </p>
          <a href="tel:07145/5246" className="font__white margin__none">
            <img src={Phone} alt="Telefonnummer" className={classes.barIcon} />
            07145/5246
          </a>
        </Grid>
      </Grid>
      <Grid container className={classes.navWrapper} id="back-to-top-anchor">
        <Link to="/" className={classes.logoWrapper}>
          <img src={Logo} alt="Hengel Logo" />
        </Link>
        <Grid item className={classes.menuWrapper}>
          <Button
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className={open ? classes.buttonBackground : undefined}
          >
            <p style={{ margin: "0px" }}>{menudata.Menu[0]}</p>
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: "1"}}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
          
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper style={{ backgroundColor: "#fff" }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {menudata.Submenu.map((item) => (
                        <Link to={item.slug}>
                        <MenuItem
                          onClick={handleClose}
                          style={{
                            borderBottom:
                              item.name === "Erneuerbare Energien"
                                ? "0"
                                : "1px solid #f7f7f7",
                          }}
                        >
                          {item.name}
                        </MenuItem></Link>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <Link to={menudata.Menu[1].slug}>
            <Button><p style={{ margin: "0px" }}>{menudata.Menu[1].name}</p></Button>
          </Link>
          <Link to={menudata.Menu[2].slug}>
            <Button><p style={{ margin: "0px" }}>{menudata.Menu[2].name}</p></Button>
          </Link>
          <Link to={menudata.Menu[3].slug}>
            <Button><p style={{ margin: "0px" }}>{menudata.Menu[3].name}</p></Button>
          </Link>
          <Link to={menudata.Menu[4].slug}>
            <Button><p style={{ margin: "0px" }}>{menudata.Menu[4].name}</p></Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
