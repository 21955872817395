import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import { gsap, Power1 } from "gsap";
import Layout from "../components/layout/layout";

const ImpressumWrapper = styled.div`
  max-width: 70vw;
  margin: 50px auto;
`;

class Impressum extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 }).to(container, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    });
  }
  render() {
    return (
      <Layout
        pageTitle="Hengel GmbH Impressum"
        description="Unser Impressum"
        index="nofollow, noindex"
      >
        <ImpressumWrapper>
          <h1 className="uppercase">Impressum</h1>
          <br />

          <h2>Angaben gemäß § 5 TMG</h2>
          <p>
            Hengel GmbH
            <br />
            Geschäftsführer Simon Schädlich            
            <br />
            Bahnhofstraße 14
            <br />
            71706 Markgröningen
          </p>
          <br />

          <h2>Kontakt</h2>
          <p>
            Telefon: <a href="tel:+4971455246">07145/5246</a>
            <br />
            Telefax: <a href="tel:+4971453551">07145/3551</a>
            <br />
            E-Mail:{" "}
            <a href="mailto:info@hengel-heizung.de"> info@hengel-heizung.de</a>
          </p>
          <br />
          <h2>HRB</h2>
          <p>
            HRB 796691 Stuttgart
          </p>
          <h2>Umsatzsteuer-ID</h2>
          <p>
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
            DE449656488
          </p>
          <br />

          <h2>Webdesign</h2>
          <p>Flowdev Web Design - FZCO, Dubai Silicon Oasis - Dubai - UAE</p>
          <br />

          <h2>Haftungshinweis:</h2>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich. Wir haben darauf keinen Einfluss. Bei Bekanntwerden
            von Rechtsverletzungen entfernen wir derartige Links umgehend.
          </p>
        </ImpressumWrapper>
      </Layout>
    );
  }
}

export default Impressum;
