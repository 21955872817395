import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { gsap, Power1 } from "gsap";

import Layout from "../components/layout/layout";
import Leistung from "../components/leistung";
import TextZwei from "../components/textZwei";
import InfoText from "../components/infoText"
import LeistungHero from "../components/leistungHero"
import Testimonial from "../components/testimonial"

//Data
import Sanitaer from "../images/Icon Sanitär.png";
import Heizungsbau from "../images/Icon Heizungsbau.png";
import Planung from "../images/Icon Badkonzepte.png";
import Background from "../images/Erneuerbare Energien.jpg"

import leistung from "../data/leistungen.json";
import data from "../data/energie.json";

const TextContainer = styled.div`
  padding: 100px 0px 20px 0px;
  text-align: center;
`;

const LeistungContainer = styled.div`
  width: 90vw;
  max-width: 1400px;
  margin: 0px auto 150px auto;
  padding: 10px;

`;



class EnergieLeistungen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 })
      .to(container, {
        duration: 0.8,
        opacity: 1,
        y: 0,        
        ease: Power1.easeOut,
      })
}
  render() {
    return (
      <Layout pageTitle="Erneuerbare Energie" description="Wir helfen Ihnen bei Ihrem Ziel, die Energie- und Wärmewende mit zu gestalten und dadurch Klimaschutz für künftige Generationen zu leisten.  ">
      <LeistungHero heading={data.heading} text={data.intro} imageUrl={`url("${Background}")`}/>
      <InfoText heading="Vertrauen Sie dem Experten" list={data.details.list}/>
      
        <TextZwei heading={data.info.heading} text={data.info.text} />
        <Testimonial heading={data.kundenstimme.heading} text={data.kundenstimme.text} />
        <hr/>
        <TextContainer>
          <h4>Wir bieten auch</h4>
        </TextContainer>
        <LeistungContainer>
          <Grid container spacing={8}>
            {leistung.Leistungen.filter(function (item) {
              if (item.heading === "Erneuerbare Energien") {
                return false;
              }
              return true;
            }).map((item) => (
              <Grid item md={4}>
                <Leistung
                  icon={
                    item.heading === "Sanitär"
                      ? Sanitaer
                      : item.heading === "Heizungsbau: Fußbodenheizungen + Kundendienst"
                      ? Heizungsbau
                      : item.heading === "Badkonzepte" &&
                        Planung
                  }
                  header={item.heading}
                  slug={item.slug}
                  text={item.text}
                />
              </Grid>
            ))}
          </Grid>
        </LeistungContainer>
      </Layout>
    );
  }
}

export default EnergieLeistungen;
